
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./apricot.scss";
import { Link } from 'gatsby';

const Apricot = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("apricot:metaTitle")}>
      <>
        <HeroImg customClass="apricot-hero" customSubtitle="apricot-subtitle" subtitle={t("apricot:heroSubtitle")} title={t("apricot:heroTitle")} backgroundImage="bg/apricot-hero.jpg"></HeroImg>

        <div className="apricot">

          <div className="apricot-section-one-mobile">
            <h1 className="box-two apricot-section-one-title">{t("apricot:sectionOneTitle")}</h1>
            <p className="box-two">{t("apricot:sectionOneTextOne")}</p>
            <p className="box-two">{t("apricot:sectionOneTextTwo")}</p>
            <p>{t("apricot:sectionOneTextThree")}</p>
          </div>
          <HeroImg customClass="apricot-section-one" backgroundImage="bg/apricot-section-one.jpg">
            {<div className="apricot-section-one-container">
              <h1 className="box apricot-section-one-title">{t("apricot:sectionOneTitle")}</h1>
              <p className="box">{t("apricot:sectionOneTextOne")}</p>
              <p className="box">{t("apricot:sectionOneTextTwo")}</p>
              <p>{t("apricot:sectionOneTextThree")}</p>
            </div>}
          </HeroImg>

          <HeroImg customClass="apricot-section-two" backgroundImage="bg/apricot-section-two.jpg">
            {<div className="apricot-section-two-container">
              <h4>{t("common:organoleptic")}</h4>
              <h3 className="box">{t("common:properties")}</h3>
              <div className="hide-on-mobile">
                <h4>{t("common:color")}</h4>
                <p className="box-two">{t("apricot:sectionTwoTextOne")}</p>
                <h4>{t("common:aroma")}</h4>
                <p>{t("apricot:sectionTwoTextTwo")}</p>
                <p className="box-two">{t("apricot:sectionTwoTextThree")}</p>
                <h4>{t("common:palate")}</h4>
                <p>{t("apricot:sectionTwoTextFour")}</p>
                <p className="box-two">{t("apricot:sectionTwoTextFive")}</p>
                <h4>{t("common:aftertaste")}</h4>
                <p>{t("apricot:sectionTwoTextSix")}</p>
                <p>{t("apricot:sectionTwoTextSeven")}</p>
                <p>{t("apricot:sectionTwoTextEight")}</p>
              </div>
            </div>}
          </HeroImg>
          <div className="apricot-section-two-mobile">
            <h4>{t("common:color")}</h4>
            <p className="box-two">{t("apricot:sectionTwoTextOne")}</p>
            <h4>{t("common:aroma")}</h4>
            <p>{t("apricot:sectionTwoTextTwo")}</p>
            <p className="box-two">{t("apricot:sectionTwoTextThree")}</p>
            <h4>{t("common:palate")}</h4>
            <p>{t("apricot:sectionTwoTextFour")}</p>
            <p className="box-two">{t("apricot:sectionTwoTextFive")}</p>
            <h4>{t("common:aftertaste")}</h4>
            <p>{t("apricot:sectionTwoTextSix")}</p>
            <p>{t("apricot:sectionTwoTextSeven")}</p>
            <p>{t("apricot:sectionTwoTextEight")}</p>
          </div>

          <HeroImg customClass="apricot-section-three" backgroundImage="bg/apricot-section-three.jpg">
            {
              <div className="apricot-section-three-container">
                <h4>{t("apricot:sectionThreeTitle")}</h4>
                <h3 className="differente-style box"><span>{t("common:pairing")}</span>{t("common:food")}</h3>
                <div className="hide-on-mobile">
                  <h4 className="differente-style-two">{t("apricot:sectionThreeTextOne")}</h4>
                  <p className="box-three">{t("apricot:sectionThreeTextTwo")}</p>
                  <p className="box">{t("apricot:sectionThreeTextThree")}</p>
                  <p className="box">{t("apricot:sectionThreeTextFour")}</p>
                  <p className="box">{t("apricot:sectionThreeTextFive")}</p>
                  <p className="box">{t("apricot:sectionThreeTextSix")}</p>
                </div>
              </div>
            }
          </HeroImg>
          <div className="apricot-section-three-mobile">
            <h4 className="differente-style-two">{t("apricot:sectionThreeTextOne")}</h4>
            <p className="box-three">{t("apricot:sectionThreeTextTwo")}</p>
            <p className="box">{t("apricot:sectionThreeTextThree")}</p>
            <p className="box">{t("apricot:sectionThreeTextFour")}</p>
            <p className="box">{t("apricot:sectionThreeTextFive")}</p>
            <p className="box">{t("apricot:sectionThreeTextSix")}</p>
          </div>
        </div>
        <HeroImg customClass="apricot-section-four" backgroundImage="bg/bg-bottle.jpg">
          {
            <div className="apricot-section-four-container">
              <h2>{t("common:otherBrandies")}</h2>
              <Link to={t("routes:gordaPlum")}><img src="/img/plum-small.png" alt="plum" /></Link>
              <Link to={t("routes:gordaQuince")}><img src="/img/quince-small.png" alt="quince" /></Link> 
              <Link to={t("routes:gordaAron")}><img src="/img/aron-small.png" alt="aaron" /></Link>      
            </div>
          }
        </HeroImg>
      </>
    </MainLayout>
  )
}

export default Apricot;
